<template>
  <div
    class="bg-white custom-breadcrumb"
    style="position: relative"
  >
    <div class="row q-mx-auto breadcrumb items-center max-container-width">
      <div class="col q-pl-xs">
        <div class="row q-gutter-sm items-center no-wrap ellipsis">
          <q-btn
            flat
            @click="() => $router.go(-1)"
            v-if="showBackButton"
            size="sm"
            padding="0em 0em"
            icon="mdi-arrow-left"
            color="primary"
            class="col-auto"
          >
            <q-tooltip>Volvar para página anterior</q-tooltip>
          </q-btn>

          <template v-for="(route, index) in breadcrumb">
            <div
              class="col-auto"
              :key="`separator-${index}`"
            >
              <q-icon
                size="1.4em"
                name="mdi-chevron-right"
                color="primary"
                v-if="index"
              />
            </div>

            <div
              :key="`item-${index}`"
              :class="['text-primary', index == (breadcrumb.length - 1) ? 'col ellipsis-2-lines' : 'col-auto cursor-pointer']"
              @click="() => index == (breadcrumb.length - 1) ? null : $router.push(route.to)"
            >
              <div v-html="breadcrumbLabel(route)" />
            </div>
          </template>
        </div>
      </div>

      <div class="col-auto q-pr-xs">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get'

export default {
  props: {
    metadata: {}
  },

  computed: {
    codeId () {
      const code = String(this.$route?.params?.id || '').trim()
      return code ? code.padStart(7, '0') : ''
    },

    breadcrumb () {
      return this.$route.meta.breadcrumb
    },

    showBackButton () {
      return Boolean(this.$route.meta.showBackButton)
    }
  },

  methods: {
    breadcrumbLabel (route) {
      return route.label
        .replace('Documento #', '#')
        .replace('#', `<b>${prepareLabel(this.codeId, this.metadata, route.field)}</b>`)
    }
  }
}

const prepareLabel = (code, data, field) => {
  const label = `#${code}`
  if (!data) return label
  if (!field) return label
  return _get(data, field) || label
}
</script>

<style lang="sass">
  .custom-breadcrumb
    border-bottom: solid 1px #CFD8DC

    .q-breadcrumbs--last
      a
        font-weight: bold

    .breadcrumb
      height:48px
</style>
